$(document).foundation();

$(function(){

	// Initialize FastClick
	FastClick.attach(document.body);

	// Initialize jQuery Placeholder
	$('input, textarea').placeholder();
	
});
$('window').on('load', function(){
	$('stripe-button-el').trigger('click');
})
